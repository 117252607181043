import { Label } from 'common/ontology'
import React, { useState } from 'react'
import { Button } from './components/Button'

const LABEL_TEXT: Record<Label, string> = {
  sourdine: '0 : sourdine / icone',
  notification: '1 : notification / vibration',
  confort: '2 : confort / sonnerie calme',
  urgent: '3 : urgent / sonnerie alarme',
}

interface Props {
  selectedLabels: string[]
  tag: (label: string) => void
}

export const Labels = ({ selectedLabels, tag }: Props) => {
  const [_hoveredLabel, setHoveredLabel] = useState<string | undefined>()

  return (
    <div className="flex flex-col justify-center">
      <div className="relative flex flex-row flex-wrap justify-evenly gap-x-8 gap-y-4 p-4 pt-6">
        {Object.keys(LABEL_TEXT).map((label) => (
          <Button
            key={label}
            primary={selectedLabels.find((l) => l === label) !== undefined}
            onClick={() => tag(label)}
            onMouseEnter={() => setHoveredLabel(label)}
            onMouseLeave={() => setHoveredLabel(undefined)}
          >
            {LABEL_TEXT[label as Label]}
          </Button>
        ))}
      </div>
    </div>
  )
}
