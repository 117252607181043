import { InputHTMLAttributes } from 'react'

type InputProps = InputHTMLAttributes<HTMLInputElement>

export const Input = ({ children, ...props }: InputProps) => (
  <input
    className="cursor-pointer rounded bg-sky-700 px-2 py-1 focus:outline-none disabled:opacity-50"
    {...props}
  >
    {children}
  </input>
)
