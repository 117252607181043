import md5 from 'md5'

export function getAlertKey(serial: string, alertID: string): string {
  return `${md5(`${serial}/${alertID}`)}_${serial}-${alertID}`
}

export function alertSortIndex(priority: number, labelersCount: number) {
  return 10 * priority + labelersCount
}

export function extractDateSerialAlertID(str: string) {
  const parts = str.split('/')
  if (parts.length !== 3) return undefined
  const [date, serial, alertID] = parts
  if (serial.length !== 16 || isNaN(new Date(date).getTime())) return undefined
  return { date, serial, alertID }
}
