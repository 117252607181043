import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import 'shared/utils/luxon'
import { App } from './App'
import './index.css'

Sentry.init({
  enabled: !!import.meta.env.VITE_VERSION,
  dsn: 'https://0b800a1c30c4f84b639e48833cb146a8@o566331.ingest.us.sentry.io/4506892982222848',
  integrations: [new Sentry.BrowserTracing()],
  environment: import.meta.env.MODE,
  tracesSampleRate: 0.1,
  release: `${import.meta.env.MODE}-${import.meta.env.VITE_VERSION}`,
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(<App />)
